import { useEffect as R, useId as F, useState as I } from "react";
import { jsx as r, jsxs as g, Fragment as _ } from "react/jsx-runtime";
import { setLoginFlowState as G } from "@vgno/account";
import { Button as w } from "@vgno/core";
import { Play as S, Replay as W, Pause as Y, AudioLow as K, AudioOff as Q, AudioOn as X } from "@vgno/icons";
import './assets/index.css';const Z = () => {
  try {
    const e = document.querySelector("#overlay-manifest");
    if (!e || !e.textContent)
      throw new Error("Could not find VGTV overlay manifest");
    return JSON.parse(e.textContent);
  } catch (e) {
    return console.warn("Failed to load VGTV overlay manifest", e), null;
  }
}, tt = () => {
  const e = document.createElement("div");
  e.id = "vgtv-overlay", document.body.appendChild(e);
}, et = (e) => {
  try {
    new URL(e);
  } catch {
    throw new Error(`Invalid stylesheet URL: ${e}`);
  }
  const n = document.createElement("link");
  n.rel = "stylesheet", n.href = e, document.head.appendChild(n);
}, nt = () => {
  const e = [
    { src: "https://vgc.no/player/player.next.min.bundled-latest.js" },
    { src: "https://vgc.no/player/player-plugin-skin-vgtv2-latest.js" },
    {
      as: "style",
      src: "https://vgc.no/player/player-skin-vgtv2-latest.css"
    }
  ], n = document.createDocumentFragment();
  for (const { as: s = "script", src: t } of e) {
    const o = document.createElement("link");
    o.href = t, o.rel = "preload", o.as = s, n.appendChild(o);
  }
  document.head.appendChild(n);
}, st = () => "noModule" in HTMLScriptElement.prototype, ot = () => {
  try {
  } catch {
    return !1;
  }
  return !0;
}, at = (e) => e.dataset.useOverlayPlayer === "true", it = (e) => {
  const n = new URL(e);
  history.pushState({}, "", n.pathname + n.search), dispatchEvent(new PopStateEvent("popstate", { state: {} }));
}, rt = async () => {
  if (!ot() || !st())
    return;
  const e = Z();
  e && (tt(), et(e["vivi.css"]), await import(
    /* @vite-ignore */
    e["vivi.js"]
  ), nt(), document.addEventListener("click", (n) => {
    var t;
    const s = (t = n.target) == null ? void 0 : t.closest(
      "a[href*='https://tv.vg.no/video/']"
    );
    s && at(s) && (n.preventDefault(), it(s.href));
  }));
}, ee = () => (R(() => {
  rt();
}, []), null);
var ct = (e, n) => async ({
  assetIds: s
}, t) => {
  if (!s.length)
    return { items: [] };
  const o = new URLSearchParams({ assetId: [...s].sort().join(",") });
  return n && o.set("provider", n), (await e("/items", { ...t, searchParams: o })).json();
}, lt = (e, n) => async ({
  assetId: s,
  playlistId: t
}, o) => {
  const c = new URLSearchParams({ assetId: String(s) });
  return t && c.set("playlistId", t), n && c.set("provider", n), (await e("/related-items", {
    ...o,
    searchParams: c
  })).json();
}, dt = (e, n) => ({
  getItems: ct(e, n),
  getRelatedItems: lt(e, n)
}), ut = class extends Error {
  constructor(e) {
    const n = e.status || e.status === 0 ? e.status : "", s = e.statusText || "", t = `${n} ${s}`.trim(), o = t ? `status code ${t}` : "an unknown error";
    super(`Request failed with ${o}`), this.name = "HttpError", this.response = e;
  }
}, mt = () => {
  try {
    if (typeof window < "u" && "fetch" in window)
      return fetch;
    if ("fetch" in globalThis)
      return fetch;
  } catch (e) {
    console.error('failed to resolve "fetch"', e);
  }
}, ft = (e) => {
  if (!e)
    return {
      signal: void 0,
      clear: () => {
      }
    };
  const n = new AbortController(), s = setTimeout(() => {
    n.abort();
  }, e), t = () => {
    clearTimeout(s);
  };
  return {
    signal: n.signal,
    clear: t
  };
}, ht = ({
  baseUrl: e,
  timeout: n,
  fetch: s = mt()
}) => {
  const t = e.replace(/\/$/, "");
  return async (o, c = {}) => {
    const { searchParams: d, timeout: l = n, ...f } = c, i = ft(l);
    let h = `${t}${o}`;
    if (d) {
      const $ = new URLSearchParams(d).toString();
      h += `?${$}`;
    }
    const u = await s(h, { ...f, signal: i.signal });
    if (i.clear(), !u.ok)
      throw new ut(u);
    return u;
  };
}, vt = () => `${window.location.origin}/stories`, gt = (e, n) => {
  let s = `${e}/embed`;
  n && (s += `?provider=${n}`);
  const t = document.createElement("iframe");
  return t.setAttribute("allow", "autoplay; web-share"), t.setAttribute("src", s), t.style.position = "fixed", t.style.left = "0px", t.style.right = "0px", t.style.top = "0px", t.style.bottom = "0px", t.style.display = "none", t.style.width = "100%", t.style.height = "100%", t.style.border = "none", t.style.backgroundColor = "#fff", t.className = "stories-overlay", t;
}, N = (e) => {
  try {
    const { pathname: n } = new URL(e), { groups: s } = n.match(/^\/stories\/(?<id>(\d+))/) || {};
    return parseInt((s == null ? void 0 : s.id) || "", 10) || void 0;
  } catch {
    return;
  }
}, pt = class extends EventTarget {
  constructor(e = {}) {
    var n, s;
    super(), this.isIframeReady = !1, this.state = "closed", this.baseUrl = (n = e.baseUrl) != null ? n : vt(), this.items = /* @__PURE__ */ new Map(), this.provider = e.provider, this.storiesApi = dt(
      ht({ baseUrl: `${this.baseUrl}/api`, timeout: (s = e.timeout) != null ? s : 1500 }),
      this.provider
    );
  }
  init() {
    if (this.iframe)
      return;
    this.iframe = gt(this.baseUrl, this.provider), document.body.appendChild(this.iframe);
    const e = (n) => {
      try {
        const s = JSON.parse(n.data);
        switch (s.type) {
          case "overlay/go-back": {
            this.dispatch("overlay/go-back");
            break;
          }
          case "overlay/state": {
            this.dispatch("overlay/state", s.payload);
            break;
          }
          case "overlay/ready": {
            this.isIframeReady = !0;
            break;
          }
        }
      } catch {
      }
    };
    window.addEventListener("message", e), this.removeIframeListener = () => {
      window.removeEventListener("message", e);
    };
  }
  destroy() {
    var e;
    (e = this.removeIframeListener) == null || e.call(this), this.state = "closed", this.isIframeReady = !1, this.iframe && (document.body.removeChild(this.iframe), this.iframe = void 0);
  }
  async preloadItem(e) {
    await this.getItem(e);
  }
  async preloadItems(e) {
    const n = e.map((o) => N(o)).filter((o) => !!o).filter((o) => !this.items.has(o)), s = this.storiesApi.getItems({ assetIds: n }), t = async (o) => {
      try {
        const { items: c } = await s;
        return c.find((d) => d.type === "asset" && d.asset.id === o);
      } catch (c) {
        console.error(c);
        return;
      }
    };
    n.forEach((o) => {
      this.items.set(o, t(o));
    });
  }
  async openItem(e) {
    const n = this.iframe;
    if (!n)
      return !1;
    const s = await this.getItem(e);
    if (!s)
      return !1;
    const t = this.postMessage({
      type: "overlay/set-item",
      payload: { item: s, url: e }
    });
    return t && (this.dispatch("overlay/open"), this.state = "open", n.style.display = "unset"), t;
  }
  getItem(e) {
    const n = N(e);
    if (!n)
      return;
    let s = this.items.get(n);
    return s || (s = this.fetchItem(n), this.items.set(n, s)), s;
  }
  closeOverlay() {
    this.iframe && this.state === "open" && (this.state = "closed", this.iframe.style.display = "none", this.postMessage({ type: "overlay/close" }), this.dispatch("overlay/close"));
  }
  async fetchItem(e) {
    try {
      const { items: n } = await this.storiesApi.getItems({ assetIds: [e] });
      return n.find((s) => s.type === "asset" && s.asset.id === e);
    } catch (n) {
      console.error(n);
      return;
    }
  }
  postMessage(e) {
    return this.iframe && this.iframe.contentWindow && this.isIframeReady ? (this.iframe.contentWindow.postMessage(JSON.stringify(e), "*"), !0) : !1;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch(e, n) {
    const s = n ? new CustomEvent(e, { detail: n }) : new Event(e);
    this.dispatchEvent(s);
  }
};
const yt = () => {
  const { hostname: e } = window.location;
  return e === "stage.vg.no" ? "https://stage.vg.no/stories" : "https://www.vg.no/stories";
}, wt = () => {
  const e = new pt({ baseUrl: yt() });
  let n = document.title, s;
  return window.addEventListener("popstate", () => {
    const { pathname: t } = new URL(location.href);
    t.match(/\/stories\/\d+/) ? e.openItem(location.href) : e.closeOverlay();
  }), e.addEventListener("overlay/open", () => {
    n = document.title, s = document.body.style.getPropertyValue("overflow"), document.body.style.overflow = "hidden";
  }), e.addEventListener("overlay/close", () => {
    n && (document.title = n), s !== void 0 && (document.body.style.overflow = s), n = "", s = void 0;
  }), e.addEventListener("overlay/state", ({ detail: t }) => {
    const { path: o, title: c } = t;
    window.history.replaceState({}, "", window.location.origin + o), document.title = c;
  }), e.addEventListener("overlay/go-back", () => {
    history.back();
  }), e;
}, kt = () => {
  const e = window.__stories_overlay ?? wt();
  window.__stories_overlay = e, e.init();
  const n = async (s) => {
    const { pathname: t, search: o } = new URL(s), c = t + o;
    await e.openItem(s) ? history.pushState({}, "", window.location.origin + c) : window.location.href = s;
  };
  document.addEventListener("click", (s) => {
    var o;
    const t = (o = s.target) == null ? void 0 : o.closest(
      "a[href*='https://www.vg.no/stories/']"
    );
    t && (s.preventDefault(), n(t.href));
  });
}, ne = () => (R(() => {
  kt();
}, []), null), se = () => window.__stories_overlay, q = "https://www.vg.no/vgc/player/player.next.min.bundled-latest.js";
let M = !1;
const bt = () => {
  if (!window || M)
    return;
  M = !0;
  const e = document.createElement("script");
  e.src = q, e.async = !0, document.head.appendChild(e);
}, O = (e) => new Promise((n) => {
  window[e] ? n(window[e]) : window.addEventListener("identity-initialized", () => {
    n(window[e]);
  });
}), Pt = async (e) => {
  const { id: n, assetType: s } = e.rawAsset, t = s === "video" ? `https://api.vg.no/svp/token/v2/${n}?access=plus` : `https://api.vg.no/svp/token/${n}?access=plus`;
  try {
    const o = await fetch(t, {
      credentials: "include"
    });
    if (!o.ok)
      throw new Error("Failed to fetch user status");
    const c = await o.json();
    return { hasProduct: c.hasAccess, loggedIn: c.loggedIn };
  } catch {
    return { hasProduct: !1, loggedIn: !1 };
  }
}, _t = (e = bt) => {
  let n = [], s = !1;
  const t = (l) => {
    const f = window.SVP.getPlayerConfig(
      {
        ads: {
          bumper: !0,
          midroll: !0,
          pausead: !0
        },
        context: l.options.context,
        noFirstAd: l.options.noFirstAd,
        paywallAccess: {
          enabled: !0,
          getSpidIdentityInstance: () => O("SPiD_Identity"),
          getSpidMonetizationInstance: () => O("SPiD_Monetization"),
          message: {
            onLoginClick: () => {
              var h, u;
              const i = G();
              (u = window.SPiD_Identity) == null || u.login({
                redirectUri: (h = window.SPiD_Identity) == null ? void 0 : h.redirectUri,
                state: i
              });
            }
          }
        },
        site: "vg"
      },
      {
        ...l.config,
        userStatus: Pt
      }
    );
    return new window.SVP.Player(f);
  }, o = () => typeof window.SVP < "u" && !!window.SVP.Player;
  return {
    initLibrary: () => {
      s || (s = !0, window.addEventListener("onSvpPlayerReady", () => {
        n.forEach((l) => l()), n = [];
      }), e());
    },
    loadVideo: (l) => new Promise((f) => {
      o() ? f(t(l)) : n.push(() => f(t(l)));
    })
  };
}, U = _t(), H = () => {
  if (!(typeof window > "u"))
    return U.initLibrary(), U.loadVideo;
}, $t = "_playerContainer_tm6eo_1", Rt = "_podcastContainer_tm6eo_12", V = {
  playerContainer: $t,
  podcastContainer: Rt
}, L = {
  audio: "https://vgc.no/player/player-skin-vgtv-latest.css",
  video: "https://vgc.no/player/player-skin-vgtv2-latest.css"
}, It = (e) => `https://www.vg.no/nyheter/i/jPJ5g9/vg?utm_content=kjopvgpluss&utm_source=vgno&asset_type=video&asset_id=${e}&asset_provider=vgtv`, oe = ({
  assetId: e,
  assetType: n = "video",
  autoplayMuted: s = !1,
  className: t,
  context: o = null,
  enableAutoPlay: c = !1,
  loading: d = "lazy",
  loop: l = !1,
  na: f = !1,
  noFirstAd: i = !1,
  videoPreview: h = !1,
  vendor: u = "vgtv",
  ...$
}) => {
  const v = H(), a = F(), m = `svp-video-${e}-${a}`, y = {
    name: n,
    url: n in L ? L[n] : L.video
  };
  return R(() => {
    const k = {
      context: o,
      noFirstAd: i
    }, p = {
      autoplay: c,
      id: Number(e),
      mute: s,
      node: m,
      repeat: l,
      settings: {
        na: f || n === "audio"
      },
      skin: y.name === "video" ? void 0 : y,
      subscriptionUrl: It(e),
      vendor: u,
      videoPreview: h
    };
    if (d === "eager") {
      v == null || v({
        config: p,
        options: k
      });
      return;
    }
    const b = new IntersectionObserver(
      (J) => {
        J[0].isIntersecting && (b.disconnect(), v == null || v({
          config: p,
          options: k
        }));
      },
      {
        rootMargin: "100%"
      }
    );
    return b.observe(document.getElementById(m)), () => b.disconnect();
  }, [e, v, c, s, d, l]), /* @__PURE__ */ r(
    "div",
    {
      ...$,
      className: t || (y.name === "audio" ? V.podcastContainer : V.playerContainer),
      children: /* @__PURE__ */ r("div", { id: m })
    }
  );
}, ae = [
  {
    as: "style",
    href: L.video
  },
  {
    as: "script",
    href: q
  }
], St = "_text_1abvd_1", Ct = {
  text: St
}, Lt = () => /* @__PURE__ */ r("p", { className: Ct.text, children: "ANNONSE" }), Et = "_duration_uj4c6_1", xt = {
  duration: Et
}, A = (e) => {
  const n = Math.floor(e / 60), s = Math.floor(e % 60), t = s < 10 ? `0${s}` : s;
  return `${n < 10 ? `0${n}` : n}:${t}`;
}, Nt = ({ currentTime: e, duration: n, className: s }) => /* @__PURE__ */ g(
  "p",
  {
    role: "timer",
    "aria-live": "off",
    className: `label-small font-inter label-secondary ${xt.duration} ${s}`,
    children: [
      A(e),
      " / ",
      A(n)
    ]
  }
), Mt = "_srOnly_6xupv_1", Ot = "_introduction_6xupv_13", B = {
  srOnly: Mt,
  introduction: Ot
}, T = (e) => {
  if (e < 60)
    return `${Math.round(e / 10) * 10} sekunder`;
  {
    const n = Math.round(e / 60);
    return `${n} minutt${n === 1 ? "" : "er"}`;
  }
}, j = ({ duration: e }) => /* @__PURE__ */ g(_, { children: [
  /* @__PURE__ */ g("p", { "aria-hidden": "true", className: B.introduction, children: [
    /* @__PURE__ */ r("strong", { children: "Lytt til saken" }),
    !!e && /* @__PURE__ */ g(_, { children: [
      " ",
      /* @__PURE__ */ r("span", { children: "•" }),
      " ",
      /* @__PURE__ */ r("span", { children: T(e) })
    ] })
  ] }),
  /* @__PURE__ */ r("p", { className: B.srOnly, children: !!e && `Avspilling har en varighet på ${T(e)}.` })
] }), Ut = "_button_vec52_1", P = {
  button: Ut
}, z = ({
  state: e,
  isApp: n,
  vendor: s,
  assetId: t,
  start: o,
  pause: c
}) => n ? /* @__PURE__ */ r(
  w,
  {
    "data-track-click-intent": "Play",
    "data-track-element-type": "Play Button",
    size: "large",
    style: "filled",
    "aria-label": "Lytt til denne saken",
    href: `audio:${s}:${t}`,
    "data-track-no-utm": !0,
    className: P.button,
    children: /* @__PURE__ */ r(S, {})
  }
) : /* @__PURE__ */ r(_, { children: (() => {
  switch (e) {
    case "buffering":
    case "loading":
    case "stalled":
      return /* @__PURE__ */ r(
        w,
        {
          "data-track-click-intent": "Attempt",
          "data-track-element-type": "Play Button Disabled",
          size: "large",
          style: "filled",
          "aria-disabled": "true",
          "aria-label": "Laster inn lytt til saken avspiller",
          className: P.button,
          children: /* @__PURE__ */ r(S, {})
        }
      );
    case "playing":
    case "adPlaying":
      return /* @__PURE__ */ r(
        w,
        {
          "data-track-click-intent": "Pause",
          "data-track-element-type": "Pause Button",
          size: "large",
          style: "filled",
          "aria-label": "Pause avspilling av saken",
          onClick: () => c(),
          className: P.button,
          children: /* @__PURE__ */ r(Y, {})
        }
      );
    case "complete":
      return /* @__PURE__ */ r(
        w,
        {
          "data-track-click-intent": "Play",
          "data-track-element-type": "Rewatch Button",
          size: "large",
          style: "filled",
          "aria-label": "Start avspilling av saken på nytt",
          onClick: () => o(),
          className: P.button,
          children: /* @__PURE__ */ r(W, {})
        }
      );
    case "idle":
      return /* @__PURE__ */ r(
        w,
        {
          "data-track-click-intent": "Play",
          "data-track-element-type": "Play Button",
          size: "large",
          style: "filled",
          "aria-label": "Lytt til denne saken",
          onClick: () => o(),
          className: P.button,
          children: /* @__PURE__ */ r(S, {})
        }
      );
    default:
      return /* @__PURE__ */ r(
        w,
        {
          "data-track-click-intent": "Resume",
          "data-track-element-type": "Resume Button",
          size: "large",
          style: "filled",
          "aria-label": "Lytt til denne saken",
          onClick: () => o(),
          className: P.button,
          children: /* @__PURE__ */ r(S, {})
        }
      );
  }
})() }), Vt = "_playbackRate_1qo2p_1", At = {
  playbackRate: Vt
}, E = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3], Bt = ({ playbackRate: e, setPlaybackRate: n }) => {
  const s = E[(E.indexOf(e) + 1) % E.length];
  return /* @__PURE__ */ g(
    w,
    {
      className: At.playbackRate,
      style: "filled",
      size: "small",
      shape: "capsule",
      "aria-label": `Endre avspillingshastighet til ${s}x`,
      onClick: () => n(s),
      "data-track-click-intent": "Update",
      "data-track-element-type": `Playback Rate Button ${s}`,
      children: [
        e,
        "x"
      ]
    }
  );
}, Tt = "_seekSlider_it4ij_1", jt = {
  seekSlider: Tt
}, D = (e) => {
  const n = Math.floor(e / 60), s = Math.floor(e % 60);
  return `${n} minutter, ${s} sekunder`;
}, zt = ({
  currentTime: e,
  duration: n,
  disableSeeking: s,
  seek: t
}) => {
  const o = e / n * 100;
  return /* @__PURE__ */ r(
    "input",
    {
      className: jt.seekSlider,
      style: {
        background: `linear-gradient(to right, var(--accent) ${o}%, #ccc ${o}%)`
      },
      onChange: (c) => {
        s || t(Number(c.target.value));
      },
      type: "range",
      id: "seek-slider",
      "aria-label": "Søkelinje",
      "aria-disabled": s,
      "aria-valuetext": `${D(e)} av ${D(n)}`,
      value: e,
      max: n,
      min: 0,
      "data-track-click-intent": "Show",
      "data-track-element-type": "Seek Slider"
    }
  );
}, Dt = "_wrapper_13ik5_1", Ft = "_volumeButton_13ik5_5", qt = "_volumeSlider_13ik5_13", x = {
  wrapper: Dt,
  volumeButton: Ft,
  volumeSlider: qt
}, Ht = ({ setVolume: e, volume: n, mute: s, muted: t }) => {
  const o = n < 25 && !t, c = n === 0 || t, d = n >= 25 && !t, l = () => {
    t && n === 0 && e(100), s(!t);
  };
  return /* @__PURE__ */ g("div", { className: x.wrapper, children: [
    /* @__PURE__ */ r(
      "input",
      {
        className: x.volumeSlider,
        style: {
          background: `linear-gradient(to right, var(--accent) ${n}%, #ccc ${n}%)`
        },
        onChange: (f) => e(Number(f.target.value)),
        type: "range",
        id: "volume-slider",
        "aria-label": "Volumkontroll",
        "aria-valuetext": `Volum ${n}%`,
        value: n,
        max: 100,
        min: 0,
        "data-track-click-intent": "Update",
        "data-track-element-type": "Volume Slider"
      }
    ),
    /* @__PURE__ */ g(
      w,
      {
        className: x.volumeButton,
        "aria-label": t ? "Slå på lyd" : "Slå av lyd",
        style: "filled",
        size: "small",
        shape: "capsule",
        onClick: l,
        "data-track-click-intent": "Update",
        "data-track-element-type": t ? "Volume On" : "Volume Off",
        children: [
          o && /* @__PURE__ */ r(K, {}),
          c && /* @__PURE__ */ r(Q, {}),
          d && /* @__PURE__ */ r(X, {})
        ]
      }
    )
  ] });
}, Jt = "_svpContainer_8u983_1", Gt = "_controls_8u983_5", Wt = "_durationInfo_8u983_11", Yt = "_duration_8u983_11", C = {
  svpContainer: Jt,
  controls: Gt,
  durationInfo: Wt,
  duration: Yt
}, ie = ({
  assetId: e,
  vendor: n = "vgtv",
  isApp: s
}) => {
  const [t, o] = I(null), [c, d] = I(!1), [l, f] = I(!1), [i, h] = I(null), u = H(), $ = F(), v = `svp-video-${e}-${$}`;
  return R(() => {
    let a = null;
    const m = {}, y = {
      vendor: n,
      node: v,
      autoplay: !1,
      id: Number(e),
      mute: !1,
      repeat: !1,
      locale: "no",
      settings: {
        na: !0
        // Disable all ads
      }
    };
    return (async () => {
      const p = await (u == null ? void 0 : u({
        config: y,
        options: m
      }));
      p && (a = p, o(p));
    })(), () => {
      a && a.remove();
    };
  }, [e, u, v, n]), R(() => {
    const a = (k = !1) => {
      var b;
      if (!k && !c) return;
      const p = ((b = t == null ? void 0 : t.getRawAsset()) == null ? void 0 : b.duration) ?? 0;
      h({
        currentTime: (t == null ? void 0 : t.getCurrentTime()) ?? 0,
        duration: (t == null ? void 0 : t.getDuration()) || p / 1e3,
        state: (t == null ? void 0 : t.getState()) ?? "idle",
        playbackRate: (t == null ? void 0 : t.getPlaybackRate()) ?? 1,
        isMuted: (t == null ? void 0 : t.getMute()) ?? !1,
        volume: (t == null ? void 0 : t.getVolume()) ?? 0
      });
    }, m = (k, p) => {
      h({
        currentTime: k,
        duration: p,
        state: (t == null ? void 0 : t.getState()) ?? "idle",
        isMuted: (t == null ? void 0 : t.getMute()) ?? !1,
        playbackRate: (t == null ? void 0 : t.getPlaybackRate()) ?? 1,
        volume: (t == null ? void 0 : t.getVolume()) ?? 0
      });
    }, y = () => {
      f(!0), a();
    };
    return t == null || t.on("assetPlay", a), t == null || t.on("assetReady", a), t == null || t.on("complete", a), t == null || t.on("error", a), t == null || t.on("mute", a), t == null || t.on("pause", a), t == null || t.on("play", a), t == null || t.on("playNext", a), t == null || t.on("volume", a), t == null || t.on("adFinished", a), t == null || t.on("adPause", a), t == null || t.on("adPlay", a), t == null || t.on("adStarted", a), t == null || t.on("playbackRateChanged", () => console.log("playbackRateChanged")), t == null || t.on("ready", () => {
      d(!0), a(!0);
    }), t == null || t.on("initialPlay", y), t == null || t.on("adProgress", m), t == null || t.on("time", m), () => {
      t == null || t.off("assetPlay", a), t == null || t.off("assetReady", a), t == null || t.off("complete", a), t == null || t.off("error", a), t == null || t.off("initialPlay", y), t == null || t.off("mute", a), t == null || t.off("pause", a), t == null || t.off("play", a), t == null || t.off("playNext", a), t == null || t.off("ready", a), t == null || t.off("volume", a), t == null || t.off("time", m), t == null || t.off("adProgress", m), t == null || t.off("adFinished", a), t == null || t.off("adPause", a), t == null || t.off("adPlay", a), t == null || t.off("adStarted", a), t == null || t.off("adProgress", m);
    };
  }, [t, c]), /* @__PURE__ */ g(_, { children: [
    /* @__PURE__ */ r("div", { className: C.svpContainer, children: /* @__PURE__ */ r("div", { id: v }) }),
    /* @__PURE__ */ r("div", { className: C.controls, children: c && !s ? /* @__PURE__ */ g(_, { children: [
      /* @__PURE__ */ r(
        z,
        {
          state: (i == null ? void 0 : i.state) ?? "idle",
          start: () => t == null ? void 0 : t.play(),
          pause: () => t == null ? void 0 : t.pause()
        }
      ),
      l ? /* @__PURE__ */ g("div", { className: C.durationInfo, children: [
        /* @__PURE__ */ r(
          zt,
          {
            disableSeeking: (i == null ? void 0 : i.state) === "adPlaying",
            currentTime: (i == null ? void 0 : i.currentTime) ?? 0,
            duration: (i == null ? void 0 : i.duration) ?? 0,
            seek: (a) => t == null ? void 0 : t.seek(a)
          }
        ),
        (i == null ? void 0 : i.state) === "adPlaying" && /* @__PURE__ */ r(Lt, {}),
        /* @__PURE__ */ r(
          Nt,
          {
            className: C.duration,
            currentTime: (i == null ? void 0 : i.currentTime) ?? 0,
            duration: (i == null ? void 0 : i.duration) ?? 0
          }
        )
      ] }) : /* @__PURE__ */ r(j, { duration: (i == null ? void 0 : i.duration) || 0 }),
      /* @__PURE__ */ r(
        Ht,
        {
          muted: (i == null ? void 0 : i.isMuted) ?? !1,
          mute: (a) => t == null ? void 0 : t.setMute(a),
          volume: (i == null ? void 0 : i.volume) ?? 100,
          setVolume: (a) => t == null ? void 0 : t.setVolume(a)
        }
      ),
      /* @__PURE__ */ r(
        Bt,
        {
          playbackRate: (i == null ? void 0 : i.playbackRate) ?? 1,
          setPlaybackRate: (a) => {
            h((m) => ({
              ...m,
              playbackRate: a
            })), t == null || t.setPlaybackRate(a);
          }
        }
      )
    ] }) : /* @__PURE__ */ g(_, { children: [
      /* @__PURE__ */ r(
        z,
        {
          isApp: s,
          vendor: n,
          assetId: e,
          state: "loading",
          start: () => {
          },
          pause: () => {
          }
        }
      ),
      /* @__PURE__ */ r(j, { duration: (i == null ? void 0 : i.duration) || 0 })
    ] }) })
  ] });
};
export {
  ee as OverlayPlayer,
  ne as StoriesOverlay,
  ie as TTSPlayer,
  oe as Video,
  se as getStoriesOverlay,
  it as openOverlay,
  ae as preloadAssets
};
